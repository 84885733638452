import { IUser } from '../../interfaces';

export type IUserDTO = Pick<
  IUser,
  | 'id'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'accessRights'
  | 'lastLogin'
  | 'language'
  | 'lastRecurringTransactionImport'
  | 'groupTransactions'
  | 'emergencyFundTargetMonths'
  | 'householdId'
>;
