import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, map, of, switchMap, tap } from 'rxjs';
import store from 'store2';
import { AuthService } from '../auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AccessTokenDTO } from '../dtos/access-token.dto';
import { ACCESS_TOKEN_KEY } from '@framework/shared/core-util-types';

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private router: Router, public authService: AuthService) {}

  canActivate(): Observable<boolean> {
    const token = store.get(ACCESS_TOKEN_KEY);
    try {
      if (token) {
        // need to check that the token is still valid
        const isExpired = helper.isTokenExpired(token);
        if (isExpired) {
          return this._tryToRefreshToken();
        } else {
          return of(true);
        }
      } else {
        // return this._logoutAndNavigateToLogin();
        return this._tryToRefreshToken();
      }
    } catch (ex) {
      return this._logoutAndNavigateToLogin();
    }
  }

  private _tryToRefreshToken(): Observable<boolean> {
    return this.authService.refreshToken().pipe(
      map((token: AccessTokenDTO) => {
        // Store the user's authentication token in local storage.
        store.set(ACCESS_TOKEN_KEY, token.access_token);
        return true;
      }),
      switchMap((result: boolean) => {
        if (!result) {
          return this._logoutAndNavigateToLogin();
        }
        return of(result);
      }),
      catchError(() => {
        this._logoutAndNavigateToLogin();
        return of(false);
      })
    );
  }

  private _logoutAndNavigateToLogin(): Observable<boolean> {
    return this.authService.signOut().pipe(
      tap(() => {
        store.clear();
      }),
      map(() => {
        this.router.navigate(['auth/login']);
        return false;
      }),
      catchError(() => {
        this.router.navigate(['auth/login']);
        return of(false);
      })
    );
  }
}
