import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ChunkErrorRetryService {
  private maxRetries = 3;
  private retryDelay = 1000; // 1 second
  private retryCount = new Map<string, number>();

  constructor(private router: Router) {}

  async handleChunkLoadError(): Promise<void> {
    const currentUrl = this.router.url;
    const currentRetries = this.retryCount.get(currentUrl) || 0;
    if (currentRetries < this.maxRetries) {
      console.log(`Retrying chunk load for ${currentRetries}...`);
      // Increment retry count
      this.retryCount.set(currentUrl, currentRetries + 1);

      // Clear browser cache for this chunk
      await this.clearCacheForCurrentChunk();

      // Wait before retrying
      await this.delay(this.retryDelay);

      // Reload the current route
      await this.reloadRoute(currentUrl);
      await this.handleChunkLoadError();
    } else {
      // Max retries reached, redirect to error page
      this.router.navigate(['/chunk-error'], {
        queryParams: {
          reason: 'chunk-load-failed',
          url: currentUrl,
        },
      });
    }
  }

  private async clearCacheForCurrentChunk(): Promise<void> {
    if ('caches' in window) {
      try {
        const cacheKeys = await caches.keys();
        await Promise.all(cacheKeys.map((key) => caches.delete(key)));
      } catch (error) {
        console.warn('Failed to clear cache:', error);
      }
    }
  }

  private async reloadRoute(url: string): Promise<void> {
    try {
      await this.router.navigateByUrl('/', { skipLocationChange: true });
      await this.router.navigate([url]);
    } catch (error) {
      console.error('Failed to reload route:', error);
    }
  }

  private delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
