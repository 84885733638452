import { CommonModule } from '@angular/common';
import { Component, Optional, Self } from '@angular/core';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { BaseInputComponent } from '../base-input.component';

@Component({
  selector: 'ui-form-toggle-switch',
  templateUrl: 'form-toggle-switch.component.html',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
})
export class FormToggleSwitchComponent extends BaseInputComponent<boolean> {
  constructor(@Self() @Optional() control: NgControl) {
    super(control);
  }
}
