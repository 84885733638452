import {
  ErrorHandler,
  NgModule,
  Optional,
  SkipSelf,
  ModuleWithProviders,
} from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalErrorHandler } from './global-error-handler';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { ErrorLoggingService } from './error-logging.service';
import { ErrorHandlerService } from './error-handler.service';
import { ChunkErrorRetryService } from './chunk-error/chunk-error-retry.service';
import { RouterModule, Routes } from '@angular/router';
import { DialogModule } from '@angular/cdk/dialog';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
  {
    path: 'chunk-error',
    loadComponent: () =>
      import('./chunk-error/chunk-error-page.component').then(
        (m) => m.ChunkErrorPageComponent
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), DialogModule, TranslateModule],
})
export class UtilErrorHandlingModule {
  constructor(@Optional() @SkipSelf() parentModule: UtilErrorHandlingModule) {
    throwIfAlreadyLoaded(parentModule, 'Util Error Handling Module');
  }

  static forRoot(): ModuleWithProviders<UtilErrorHandlingModule> {
    return {
      ngModule: UtilErrorHandlingModule,
      providers: [
        ChunkErrorRetryService,
        ErrorHandlerService,
        ErrorLoggingService,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpErrorInterceptor,
          multi: true,
        },
      ],
    };
  }
}

const throwIfAlreadyLoaded = (
  parentModule: unknown,
  moduleName: string
): void => {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import ${moduleName} in the AppModule only.`
    );
  }
};
