import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[uiFormGrid]',
  standalone: true,
})
export class FormGridDirective {
  private readonly _mainClass =
    'ui-form-grid mt-3 mb-3 grid grid-cols-2 gap-y-3.5 gap-x-4 sm:grid-cols-6';

  @HostBinding('class')
  private twClasses = `${this._mainClass} `;
}
