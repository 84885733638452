import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'constraints',
  standalone: true, // Mark the pipe as standalone if using standalone components
})
export class ConstraintsPipe implements PipeTransform {
  transform(constraints: { [key: string]: string }): string[] {
    return Object.values(constraints);
  }
}
