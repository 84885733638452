import { Directive, HostBinding, Input } from '@angular/core';

export type ButtonTheme =
  | 'primary'
  | 'secondary'
  | 'accent'
  | 'danger'
  | 'light'
  | 'input';

export type ButtonSize = 'xs' | 'sm' | 'base' | 'lg' | 'xl';

@Directive({
  selector: '[uiButton]',
  standalone: true,
})
export class ButtonDirective {
  private readonly _mainAppButtonClass = 'btn';

  @HostBinding('class')
  private twClasses = `${this._mainAppButtonClass} `;

  private _theme: ButtonTheme = 'primary';
  @Input()
  set theme(value: ButtonTheme) {
    this._theme = value;
    this._updateClasses();
  }

  private _size: ButtonSize = 'base';
  @Input()
  set size(value: ButtonSize) {
    this._size = value;
    this._updateClasses();
  }

  private _outline = false;
  @Input()
  set outline(value: boolean) {
    this._outline = value;
    this._updateClasses();
  }

  private _pills = false;
  @Input()
  set pills(value: boolean) {
    this._pills = value;
    this._updateClasses();
  }

  private _updateClasses() {
    this.twClasses = `${this._mainAppButtonClass} btn-${this._theme} ${
      this._size ? `btn-size-${this._size}` : ''
    } ${this._outline ? 'btn-outline' : ''} ${this._pills ? 'btn-pills' : ''}`;
  }
}
