import {
  Component,
  inject,
  HostListener,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { BaseComponent } from '../base-component/base.component';
import { AbstractCrudService } from '../base-service';
import { BaseApiErrorResponse } from '../errors';
import { FormHelperService } from '../services';
import { Dialog } from '@angular/cdk/dialog';

export type FormType<T> = {
  [K in keyof T]: FormControl;
};

@Component({
  template: '',
})
export abstract class AbstractFormComponent<
  T extends { id: string }
> extends BaseComponent {
  @Output() entitySaved: EventEmitter<void> = new EventEmitter<void>();

  protected readonly formHelperService: FormHelperService =
    inject(FormHelperService);
  protected readonly dialogService: Dialog = inject(Dialog);
  protected readonly translateService: TranslateService =
    inject(TranslateService);

  public isLoadingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  protected readonly isLoadingData$ = this.isLoadingData.asObservable();

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander() {
    return !this.formHelperService.areControlTouchedOrDirty(this.form);
  }

  private _entity?: T;
  get entity(): T | undefined {
    return this._entity;
  }
  set entity(value: T | undefined) {
    this._entity = value;
  }

  private _form?: FormGroup<FormType<T>>;
  get form(): FormGroup<FormType<T>> {
    if (!this._form) {
      throw new Error('Form is not created');
    }
    return this._form;
  }
  set form(value: FormGroup<FormType<T>> | undefined) {
    this._form = value;
  }

  private _entitySavedSuccessfully = false;
  get entitySavedSuccessfully(): boolean {
    return this._entitySavedSuccessfully;
  }

  abstract onEntitySaved(): void;

  // Abstract method to handle form submission specific to the component
  abstract submitForm(): void;

  // Abstract method to create the form controls for the specific component
  abstract createForm(data?: unknown): FormGroup<FormType<T>>;

  constructor(protected service: AbstractCrudService<T>) {
    super();
  }

  protected onSubmit() {
    if (this.formHelperService.validateForm(this.form)) {
      this.submitForm();
    }
  }

  protected saveEntity(entity: T) {
    (entity.id ? this.service.update(entity) : this.service.post(entity))
      .pipe(takeUntil(this._ngUnSubscribe))
      .subscribe({
        next: () => {
          this.processEntitySaveSuccess();
        },
        error: (err: BaseApiErrorResponse) => {
          this.onErrorHandler(err);
        },
      });
  }

  protected processEntitySaveSuccess() {
    this._entitySavedSuccessfully = true;
    this.entitySaved.emit();
    this.onEntitySaved();
  }

  protected getData(entityId: string) {
    this.isLoadingData.next(true);
    this.service
      .get(entityId)
      .pipe(takeUntil(this._ngUnSubscribe))
      .subscribe({
        next: (entity) => {
          this.onEntityLoaded(entity);
          this.isLoadingData.next(false);
        },
        error: (err) => {
          this.isLoadingData.next(false);
          this.onErrorHandler(err);
        },
      });
  }

  protected onEntityLoaded(entity: T) {
    this._entity = entity;
    this.form.patchValue(entity as object);
  }

  protected onErrorHandler(err: unknown) {
    // TODO: handle validation errors
    if (typeof err === 'string' || err === undefined) {
      // Handle the error as a string or undefined
      console.error(err);
      throw new Error(err);
    } else if (err instanceof Error) {
      // Handle the error as an instance of Error
      console.error(err.message);
      throw new Error(err.message);
    } else {
      // Handle other types of errors if necessary
      console.error(err);
      throw new Error('An unknown error occurred');
    }
  }
}
