import { ErrorHandler, NgModule, inject } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser'; // Import BrowserModule
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  APP_NAME,
  CoreModule,
  EnvironmentType,
} from '@budget-compass/frontend-core';
import { environment } from '../environments/environment';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './auth/interceptors/token.interceptor';
import { ToastModule } from '@budget-compass/frontend-ui';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ChunkErrorRetryService } from './core/error-handlers/chunk-error/chunk-error-retry.service';
import { ChunkErrorHandler } from './core/error-handlers/chunk-error/chunk-error.handler';

const routes: Routes = [
  {
    path: 'secure',
    loadChildren: () =>
      import('./secure/secure.module').then((m) => m.SecureModule),
    canActivate: [() => inject(AuthGuard).canActivate()],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'chunk-error',
    loadComponent: () =>
      import(
        './core/error-handlers/chunk-error/chunk-error-page.component'
      ).then((m) => m.ChunkErrorPageComponent),
  },
  { path: '**', redirectTo: 'auth', pathMatch: 'full' },
];

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule.forRoot(environment),
    RouterModule.forRoot(routes),
    ToastModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.environmentType !== EnvironmentType.Local,
      // enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    { provide: APP_NAME, useValue: 'Budget Compass' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    ChunkErrorRetryService,
    { provide: ErrorHandler, useClass: ChunkErrorHandler },
  ],
})
export class AppModule {}
