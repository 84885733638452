import { Observable, Subject } from 'rxjs';
import { IReadOnlyService } from './read-only-service.iterface';

export interface ICrudService<T> extends IReadOnlyService<T> {
  reloadData: Subject<void>;
  get reloadData$(): Observable<void>;
  post(entity: T): Observable<T>;
  update(entity: T): Observable<T>;
  delete(id: string): Observable<unknown>;
}
