const OBaseAccessRight = {
  ChangePassword: -1,
  InteractiveUser: 0,
  BaseDataManagement: 1,
  UserManagement: 2,
  RoleManagement: 3,
} as const;

export const OAppAccessRight = {
  ...OBaseAccessRight,
  // Add your custom access rights here
} as const;

export type AppAccessRight =
  (typeof OAppAccessRight)[keyof typeof OAppAccessRight];
