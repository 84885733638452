import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DialogRef, DIALOG_DATA, DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ButtonDirective } from '@budget-compass/frontend-ui';
import { TranslateModule } from '@ngx-translate/core';
import {
  APP_ENV,
  EnvironmentType,
  IEnvironment,
} from '@budget-compass/frontend-core';

export type ErrorDialogData = {
  message: string;
  error: Error | HttpErrorResponse;
  validationErrors?: Record<string, string[]>;
};

@Component({
  selector: 'lib-error-dialog',
  template: `
    <div
      class="transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-xl"
    >
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10"
          >
            <svg
              class="size-6 text-red-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
              data-slot="icon"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-base font-semibold text-gray-900" id="modal-title">
              A Error Occurred
            </h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">
                {{ data.message }}
              </p>
            </div>
            <div class="mt-4" *ngIf="env !== environmentType.Production">
              <p
                class="text-sm text-gray-500 whitespace-pre-wrap border-l-4 border-red-500 pl-2"
                *ngIf="error && error.stack"
              >
                {{ error.stack }}
              </p>
              <p
                class="text-sm text-gray-500 whitespace-pre-wrap border-l-4 border-red-500 pl-2"
                *ngIf="httpError && httpError.error.stackTrace"
              >
                {{ httpError.error.stackTrace }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="border-gray-200 bg-gray-100 py-3 flex px-6 text-right justify-end items-center"
      >
        <button
          uiButton
          [outline]="true"
          theme="light"
          type="button"
          class="space-x-2 w-24"
          (click)="onClose()"
        >
          {{ 'Default.modal.action.close' | translate }}
        </button>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DialogModule, CommonModule, ButtonDirective, TranslateModule],
})
export class ErrorDialogComponent {
  private _error: Error;
  public get error(): Error {
    return this._error;
  }
  private _httpError: HttpErrorResponse;
  public get httpError(): HttpErrorResponse {
    return this._httpError;
  }

  public env: EnvironmentType = this._env.environmentType;
  public readonly environmentType = EnvironmentType;

  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: ErrorDialogData,
    @Inject(APP_ENV) private _env: IEnvironment
  ) {
    if (data.error instanceof Error) {
      this._error = data.error;
    }
    if (data.error instanceof HttpErrorResponse) {
      this._httpError = data.error;
    }
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
