import { IBaseModel, IUserData } from '@framework/shared/core-util-types';

export type ITagEntity = {
  name: string;
  description?: string;
  color?: string;
} & IBaseModel &
  IUserData;

export type ITag = ITagEntity;
