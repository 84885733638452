import { IBaseModel, IUserData, Uuid } from '@framework/shared/core-util-types';
import { BudgetType } from '../enums';
import { IBankAccountEntity } from './bank-account.interface';

export type IBudgetAllocationEntity = {
  description: string;
  percent: number;
  budgetType: BudgetType;
  bankAccountId?: Uuid;
  bankAccount?: IBankAccountEntity;
} & IBaseModel &
  IUserData;

export type IBudgetAllocation = IBudgetAllocationEntity;
