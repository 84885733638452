import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-form-control',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="form-control">
      <label
        [for]="id"
        class="block text-sm font-medium text-gray-700 mb-1"
        *ngIf="label"
      >
        {{ label }}
        {{ isRequired ? '*' : '' }}
      </label>
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </div>
    <!-- <ng-template #inlineTemplate>
      <div class="form-control flex items-center">
        <div class="w-1/2">
          <label [for]="id" class="form-control-label inline" *ngIf="label">
            {{ label }}
            {{ isRequired ? '*' : '' }}
          </label>
        </div>
        <div class="w-1/2">
          <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
        </div>
      </div>
    </ng-template> -->
    <ng-template #contentTpl><ng-content></ng-content></ng-template>
  `,
  styles: [
    `
      .form-control {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormControlComponent {
  @Input() label?: string;
  @Input() id!: string;
  @Input() isRequired = false;
  @Input() inline = false;
}
