import { IBaseModel, IUserData, Uuid } from '@framework/shared/core-util-types';
import {
  BudgetType,
  TransactionSource,
  TransactionStatus,
  TransactionType,
} from '../enums';
import { ICategoryEntity } from './category.interface';
import { IBankAccountEntity } from './bank-account.interface';
import { IMonthEndCloseEntity } from './month-end-close.interface';

export type ITransactionEntity = {
  // description: string;
  amount: number;
  transactionSource: TransactionSource;
  categoryId: Uuid;
  category: ICategoryEntity;
  date: Date;
  notes?: string;
  recurring: boolean;
  status: TransactionStatus;
  bankAccountId: Uuid;
  bankAccount: IBankAccountEntity;
  monthEndCloseId?: Uuid;
  monthEndClose?: IMonthEndCloseEntity;
  budgetType?: BudgetType;
  transactionType: TransactionType;
  tagIds: Uuid[];
} & IBaseModel &
  IUserData;

export type ITransaction = ITransactionEntity;
