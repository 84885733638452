import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {} // Inject Injector

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Forbidden) {
          console.log('User is not authorized to access the resource');
          //TODO: show a dialog to the user that he is not authorized to access the resource
          return of();
        }
        // Lazily get ErrorHandlerService to avoid circular dependency
        // const errorHandler = this.injector.get(ErrorHandlerService);
        // errorHandler.handleError(error); // Delegate error handling
        return throwError(() => error); // Rethrow for observables
      })
    );
  }
}
