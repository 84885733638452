import { Inject, Injectable } from '@angular/core';
import { finalize, Observable, Subject } from 'rxjs';
import { ReadOnlyService } from './read-only.service';
import { ICrudService } from './interface/crud-service.interface';

@Injectable()
export class CrudService<T>
  extends ReadOnlyService<T>
  implements ICrudService<T>
{
  reloadData: Subject<void> = new Subject<void>();

  constructor(@Inject('url') url: string, @Inject('server') server: string) {
    super(url, server);
  }

  get reloadData$(): Observable<void> {
    return this.reloadData.asObservable();
  }

  post(entity: T): Observable<T> {
    this.checkUrlIsSet();
    this.loadingService.setLoading(true);
    const url = `${this.serverUrl}/${this.apiUrl}`;
    return this.http.post<T>(url, entity).pipe(
      finalize(() => {
        this.loadingService.setLoading(false);
        this.reloadData.next();
      })
    );
  }

  update(entity: T): Observable<T> {
    this.checkUrlIsSet();
    this.loadingService.setLoading(true);
    const url = `${this.serverUrl}/${this.apiUrl}`;
    return this.http.put<T>(url, entity).pipe(
      finalize(() => {
        this.loadingService.setLoading(false);
        this.reloadData.next();
      })
    );
  }

  delete(id: string): Observable<unknown> {
    this.checkUrlIsSet();
    this.loadingService.setLoading(true);
    const url = `${this.serverUrl}/${this.apiUrl}/${id}`;
    return this.http.delete(url).pipe(
      finalize(() => {
        this.loadingService.setLoading(false);
        this.reloadData.next();
      })
    );
  }
}

// post(entity: T): Observable<T> {
//   this.checkUrlIsSet();
//   this.loadingService.setLoading(true);
//   const url = `${this.serverUrl}/${this.apiUrl}`;
//   return this.http
//     .post<T>(url, entity)
//     .pipe(finalize(() => this.loadingService.setLoading(false)));
// }

// update(entity: T): Observable<T> {
//   this.checkUrlIsSet();
//   this.loadingService.setLoading(true);
//   const url = `${this.serverUrl}/${this.apiUrl}`;
//   return this.http
//     .put<T>(url, entity)
//     .pipe(finalize(() => this.loadingService.setLoading(false)));
// }

// delete(id: string): Observable<unknown> {
//   this.checkUrlIsSet();
//   this.loadingService.setLoading(true);
//   const url = `${this.serverUrl}/${this.apiUrl}/${id}`;
//   return this.http
//     .delete(url)
//     .pipe(finalize(() => this.loadingService.setLoading(false)));
// }
// }
