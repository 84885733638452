<ui-form-control
  [label]="label"
  [id]="id"
  [isRequired]="hasRequiredValidator ?? false"
  [inline]="inline"
>
  <select
    multiple
    [formControl]="formControl"
    [id]="id"
    [name]="name"
    uiInput
    [class.invalid-input]="(isControlValid$ | async) === false"
    [class.disabled]="formControl.disabled"
    (blur)="onTouch()"
  >
    <option
      [value]="null"
      [disabled]="hasRequiredValidator ?? false"
      selected
      *ngIf="!hidePlaceholder"
    >
      {{
        placeholder !== ''
          ? placeholder
          : ('Default.formComponents.selectAnOption' | translate)
      }}
    </option>
    <option *ngFor="let option of options | sortOptions" [value]="option.value">
      {{ option.label }}
    </option>
  </select>
  <ui-form-error
    [isValid]="isControlValid$ | async"
    [errorMessages]="errorMessages"
    [hasErrors]="hasErrors"
  ></ui-form-error>
</ui-form-control>
