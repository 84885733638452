import { IBaseModel, IUserData } from '@framework/shared/core-util-types';
import { BankAccountNature, InterestPaymentFrequency } from '../enums';

export type IBankAccountEntity = {
  accountName: string;
  currentBalance: number;
  institutionName: string;
  primary: boolean;
  accountNature: BankAccountNature;
  creditLimit: number; // default 0
  annualInterestRate: number; // default 0
  lastInterestPaymentDate?: Date;
  interestPaymentFrequency?: InterestPaymentFrequency;
} & IBaseModel &
  IUserData;

export type IBankAccount = IBankAccountEntity;
