import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DialogRef, DialogModule, DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ButtonDirective } from '@budget-compass/frontend-ui';
import { TranslateModule } from '@ngx-translate/core';
import { ValidationError } from './validation-error.model';
import { ConstraintsPipe } from './constraints.pipe';
@Component({
  selector: 'lib-validation-error-dialog',
  template: `
    <div
      class="transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-xl"
    >
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:size-10"
          >
            <svg
              class="size-6 text-yellow-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
              data-slot="icon"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v2m0 4h.01M12 2a10 10 0 100 20 10 10 0 000-20z"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-base font-semibold text-gray-900" id="modal-title">
              One or more validation errors occurred
            </h3>
            <div class="mt-2">
              <ul class="text-sm text-gray-500">
                <li *ngFor="let error of data.validationErrors">
                  <strong>{{ error.value.property }}:</strong>
                  <ul>
                    <li
                      *ngFor="
                        let constraint of error.value.constraints | constraints
                      "
                    >
                      {{ constraint }}
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        class="border-gray-200 bg-gray-100 py-3 flex px-6 text-right justify-end items-center"
      >
        <button
          uiButton
          [outline]="true"
          theme="light"
          type="button"
          class="space-x-2 w-24"
          (click)="onClose()"
        >
          {{ 'Default.modal.action.close' | translate }}
        </button>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DialogModule,
    CommonModule,
    ButtonDirective,
    TranslateModule,
    ConstraintsPipe,
  ],
})
export class ValidationErrorDialogComponent {
  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: { validationErrors: ValidationError[] }
  ) {}
  onClose(): void {
    this.dialogRef.close();
  }
}
