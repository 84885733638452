/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import store from 'store2';
import { AuthService } from '../auth.service';
import { AccessTokenDTO } from '../dtos/access-token.dto';
import { Router } from '@angular/router';
import { ACCESS_TOKEN_KEY } from '@framework/shared/core-util-types';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  constructor(public authService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    if (token) {
      request = this.addToken(request, token);
    }
    if (request.url.includes('refresh') || request.url.includes('i18n')) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === HttpStatusCode.Unauthorized
        ) {
          if (request.url.includes('refresh')) {
            this.authService.signOut(); // Clear any stored data
            this.router.navigate(['auth/login']);
            return throwError(() => error);
          }
          return this.handle401Error(request, next);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: AccessTokenDTO) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.access_token);
          store.set(ACCESS_TOKEN_KEY, token.access_token);
          return next.handle(this.addToken(request, token.access_token));
        }),
        catchError((error) => {
          this.isRefreshing = false;
          this.authService.signOut(); // Clear any stored data
          this.router.navigate(['auth/login']);
          return throwError(() => error);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          if (token) {
            return next.handle(this.addToken(request, token));
          } else {
            return throwError(() => new Error('Refresh token failed'));
          }
        })
      );
    }
  }
}
