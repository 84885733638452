import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

export interface DecisionDialogData {
  message?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButtonColor?: 'success' | 'danger';
  invertButtons?: boolean;
}

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './decision-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DecisionDialogComponent {
  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: DecisionDialogData
  ) {
    if (data.confirmButtonColor === undefined) {
      data.confirmButtonColor = 'danger';
      data.invertButtons = false;
    }
  }

  close(result: boolean) {
    this.dialogRef.close(result);
  }
}
