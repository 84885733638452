import { IBaseModel, IUserData } from '@framework/shared/core-util-types';
import { BudgetType, TransactionType } from '../enums';

export type ICategoryEntity = {
  name: string;
  notes?: string;
  transactionType: TransactionType;
  budgetType?: BudgetType;
  allowCombineAmounts: boolean;
} & IBaseModel &
  IUserData;

export type ICategory = ICategoryEntity;
