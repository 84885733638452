<ui-form-control
  [label]="label"
  [id]="id"
  [isRequired]="hasRequiredValidator ?? false"
>
  <div class="relative">
    <div
      *ngIf="
        (!hasRequiredValidator ?? false) &&
        formControl.value !== null &&
        required === false
      "
      class="absolute grid w-5 h-5 place-items-center text-blue-gray-500 top-2/4 -translate-y-2/4 z-10 cursor-pointer"
      [ngClass]="{
        'right-10': !hideNavigation,
        'right-4': hideNavigation,
       }"
      (click)="formControl.reset()"
    >
      <i class="fa-solid fa-circle-xmark bg-white"></i>
    </div>
    <div class="flex items-center">
      <button
        uiButton
        theme="input"
        [size]="size"
        (click)="decreaseMonth()"
        class="border-r-0 rounded-r-none h-[38px] border !border-primary-300 focus:ring-primary-500 focus:border-primary-500"
        *ngIf="!hideNavigation"
        [disabled]="formControl.disabled || disableBackward"
      >
        <i class="fa-solid fa-angle-left"></i>
      </button>
      <input
        type="month"
        [formControl]="formControl"
        [id]="id"
        [name]="name"
        uiInput
        [min]="min"
        [max]="max"
        [size]="size"
        [class.disabled]="formControl.disabled"
        [class.invalid-input]="(isControlValid$ | async) === false"
        (blur)="onTouch()"
        [ngClass]="{
          'rounded-none': !hideNavigation,
        }"
      />
      <button
        uiButton
        theme="input"
        (click)="increaseMonth()"
        [size]="size"
        class="border-l-0 rounded-l-none h-[38px] border !border-primary-300 focus:ring-primary-500 focus:border-primary-500"
        *ngIf="!hideNavigation"
        [disabled]="formControl.disabled || disableForward"
      >
        <i class="fa-solid fa-angle-right"></i>
      </button>
    </div>
  </div>

  <ui-form-error
    [isValid]="isControlValid$ | async"
    [errorMessages]="errorMessages"
    [hasErrors]="hasErrors"
  ></ui-form-error>
</ui-form-control>
