<div
  class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-25 z-50"
>
  <div id="deleteModal" tabindex="-1" class="w-96">
    <div class="relative p-4 w-full max-w-md h-full md:h-auto">
      <!-- Modal content -->
      <div
        class="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5"
      >
        <button
          type="button"
          (click)="close(false)"
          class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xs p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          data-modal-toggle="deleteModal"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span class="sr-only">
            {{ 'components.decisionDialog.closeDialog' | translate }}
          </span>
        </button>
        <svg
          class="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
          />
        </svg>
        <p class="mb-4 text-gray-500 dark:text-gray-300">
          {{
            data.message ??
              ('components.decisionDialog.defaultMessage' | translate)
          }}
        </p>
        <div
          class="flex justify-center items-center gap-4"
          [ngClass]="{ 'flex-row-reverse': data.invertButtons }"
        >
          <button
            (click)="close(false)"
            type="button"
            class="py-2 px-3 text-xs font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10"
          >
            {{
              data.cancelButtonText ??
                ('components.decisionDialog.defaultCancelButtonText'
                  | translate)
            }}
          </button>
          <button
            type="button"
            class="py-2 px-3 text-xs font-medium text-white rounded-lg border border-gray-200 focus:ring-4 focus:outline-none focus:z-10"
            [ngClass]="{
              'bg-red-600 hover:bg-red-800 focus:ring-red-300': data.confirmButtonColor === 'danger' || data.confirmButtonColor === undefined,
              'bg-primary-600 hover:bg-primary-800 focus:ring-primary-300': data.confirmButtonColor === 'success',
            }"
            (click)="close(true)"
          >
            {{
              data.confirmButtonText ??
                ('components.decisionDialog.defaultConfirmButtonText'
                  | translate)
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
