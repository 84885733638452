import { IBaseModel, IUserData } from '@framework/shared/core-util-types';

export type IMonthEndCloseEntity = {
  closingDate: Date;
  totalIncome: number;
  totalExpenses: number;
  netCashFlow: number;
  // percentToInvestments: number;
  // amountToInvestments: number;
  // endingBalance: number;
  notes?: string;
} & IBaseModel &
  IUserData;
