import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ui-page-header',
  templateUrl: 'page-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class PageHeaderComponent {
  @Input({ required: true }) title!: string;

  // public readonly loading$ = this._loadingService.loading$;

  // constructor(private _loadingService: LoadingService) {}

  // @Input() mode: PageHeaderMode = PageHeaderMode.DEFAULT;
  // @Input() addButtonLabel?: string;

  // @Output() saveEvent: EventEmitter<void> = new EventEmitter<void>();
  // @Output() cancelEvent: EventEmitter<void> = new EventEmitter<void>();

  // public readonly pageHeaderMode = PageHeaderMode;
}
