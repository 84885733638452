import { IBudgetAllocationEntity } from '../../interfaces';
import { IFundsOverviewDto } from './funds-overview.dto';

export type IBudgetMonthlyOverview = {
  budgetAllocation: IBudgetAllocationEntity;
  currentMonth: IFundsOverviewDto;
  previousMonth: IFundsOverviewDto;
};

export type IMonthlyBudgetsOverviewDto = {
  monthlyBudgets: IBudgetMonthlyOverview[];
};
