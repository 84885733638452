import { AbstractControl, ValidationErrors } from '@angular/forms';

export function passwordValidator(
  control: AbstractControl
): ValidationErrors | null {
  const password = control.value;

  if (!password) {
    return null;
  }

  // Check for at least one special character
  const hasSpecialChar = /[!#$%^&*()\-_=+.]/.test(password);

  // Check for minimum length
  const hasMinLength = password.length >= 6;

  // Check for at least one number
  const hasNumber = /\d/.test(password);

  // Return validation errors if any requirements are not met
  if (!hasSpecialChar || !hasMinLength || !hasNumber) {
    return {
      passwordInvalid: true,
    };
  }

  // Return null if all requirements are met
  return null;
}
