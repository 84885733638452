import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'ui-main-content',
  template: `
    <main>
      <div class="max-w-8xl mx-auto grid grid-cols-12 pb-12 gap-x-4">
        <div class="col-span-full">
          <ng-content></ng-content>
          <!-- <div class="max-w-7xl mx-auto pt-2 sm:px-6 lg:px-8">
                    </div> -->
        </div>
      </div>
    </main>
  `,
  standalone: true,
  imports: [CommonModule],
})
export class MainContentComponent {}
