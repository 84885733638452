import {
  IBankAccountEntity,
  TransactionRecurrenceFrequency,
  TransactionSource,
} from '..';
import { IBaseModel, IUserData, Uuid } from '@framework/shared/core-util-types';
import { ICategoryEntity } from './category.interface';

export type IRecurringTransactionEntity = {
  amount: number;
  startDate?: Date;
  endDate?: Date;
  // subscription: boolean; TODO: future feature
  frequency: TransactionRecurrenceFrequency;
  interval: number;
  transactionSource: TransactionSource;
  categoryId: Uuid;
  category: ICategoryEntity;
  bankAccountId: Uuid;
  bankAccount: IBankAccountEntity;
  notes?: string;
  tagIds: Uuid[];
} & IBaseModel &
  IUserData;
