import { Component, inject, Inject } from '@angular/core';
import { takeUntil, Observable, of } from 'rxjs';
import { AbstractCrudService } from '../base-service';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { LoadingService } from '../services';
import { AbstractFormComponent } from './abstract-form.component';
import { DecisionDialogComponent } from '../ui/decision-dialog/decision-dialog.component';
import { IIdentity } from '@framework/shared/core-util-types';

export type FormDialogData = object & {
  entityId?: string;
};

@Component({ template: '' })
export abstract class AbstractDialogFormComponent<
  T extends IIdentity
> extends AbstractFormComponent<T> {
  protected readonly loadingService: LoadingService = inject(LoadingService);
  public readonly isCreating: boolean = true;
  public saveAndNew = false;

  protected abstract getInitialFormValues(): Partial<T> | null;

  constructor(
    service: AbstractCrudService<T>,
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) protected dialogData: FormDialogData
  ) {
    super(service);
    this.form = this.createForm(dialogData);
    if (dialogData.entityId) {
      this.isCreating = false;
      this.getData(dialogData.entityId);
    }
  }

  override onEntitySaved(): void {
    if (this.saveAndNew) {
      this.resetFormForNewEntry();
    } else {
      this.dialogRef.close(true);
    }
  }

  protected resetFormForNewEntry(): void {
    const initialValues = this.getInitialFormValues();
    if (initialValues) {
      this.form.reset(initialValues as never, {
        emitEvent: false,
        onlySelf: true,
      });
      this.form.updateValueAndValidity();
      // TODO: reset state of all single form controls recursively
    }
  }

  protected close() {
    this._canDeactivateDialog()
      .pipe(takeUntil(this._ngUnSubscribe))
      .subscribe((canDeactivate) => {
        if (canDeactivate) {
          this.dialogRef.close(false);
        }
      });
  }

  private _canDeactivateDialog(): Observable<unknown> {
    if (
      !this.entitySavedSuccessfully &&
      this.formHelperService.areControlTouchedOrDirty(this.form)
    ) {
      const dialogRef = this.dialogService.open(DecisionDialogComponent, {
        data: {
          message: this.translateService.instant(
            'Default.Dialog.confirmationMessage'
          ),
        },
      });
      return dialogRef.closed;
    } else {
      return of(true);
    }
  }
}
