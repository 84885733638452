import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ICrudService } from './interface/crud-service.interface';

@Injectable()
export abstract class AbstractCrudService<T> implements ICrudService<T> {
  abstract reloadData: Subject<void>;
  abstract get reloadData$(): Observable<void>;

  abstract get(id: string): Observable<T>;
  abstract post(entity: T): Observable<T>;
  abstract update(entity: T): Observable<T>;
  abstract delete(string: string): Observable<unknown>;
  abstract getAll(): Observable<T[]>;
}
