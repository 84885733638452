import { Injectable, inject } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  FormArray,
  FormBuilder,
} from '@angular/forms';

@Injectable()
export class DeepPatchValueService {
  protected readonly formBuilder: FormBuilder = inject(FormBuilder);

  /**
   * Recursively patches values into a form control, supporting nested objects, form groups, and form arrays
   * @param control The target AbstractControl to patch values into
   * @param values The object or array containing values to patch
   * @param options Optional configuration for patching
   */
  deepPatchValue<T extends { [key: string]: unknown }>(
    control: AbstractControl,
    values: T | T[],
    options: {
      emitEvent?: boolean;
      updateOnlySelf?: boolean;
    } = {}
  ): void {
    // Default options
    const patchOptions = {
      emitEvent: options.emitEvent ?? true,
      updateOnlySelf: options.updateOnlySelf ?? false,
    };

    // Handle FormArray case
    if (control instanceof FormArray) {
      this.patchFormArray(control, values as T[], patchOptions);
      return;
    }

    // Handle FormGroup case
    if (control instanceof FormGroup) {
      this.patchFormGroup(control, values as T, patchOptions);
      return;
    }

    // Handle simple form control
    control.patchValue(values, {
      emitEvent: patchOptions.emitEvent,
      onlySelf: patchOptions.updateOnlySelf,
    });
  }

  /**
   * Patch a FormArray with an array of values
   */
  private patchFormArray(
    formArray: FormArray,
    values: { [key: string]: unknown }[],
    options: { emitEvent: boolean; updateOnlySelf: boolean }
  ): void {
    // Ensure values is an array
    if (!Array.isArray(values)) {
      console.warn('Values for FormArray must be an array');
      return;
    }

    // create for every value a new form group and add it to the form array
    // the formGruop will have the keys of the value object as form controls
    while (formArray.length < values.length) {
      const formGroup = this.formBuilder.group({});
      Object.keys(values[formArray.length]).forEach((key) => {
        formGroup.addControl(
          key,
          this.createFormControl(values[formArray.length][key])
        );
      });
      formArray.push(formGroup);
    }

    // Patch each control in the array
    values.forEach((value, index) => {
      if (index < formArray.length) {
        const control = formArray.at(index);

        // Recursively patch nested structures
        if (typeof value === 'object' && value !== null) {
          this.deepPatchValue(
            control,
            value as { [key: string]: unknown },
            options
          );
        } else {
          control.patchValue(value, {
            emitEvent: options.emitEvent,
            onlySelf: options.updateOnlySelf,
          });
        }
      }
    });
  }

  /**
   * Patch a FormGroup with an object of values
   */
  private patchFormGroup(
    formGroup: FormGroup,
    values: { [key: string]: unknown },
    options: { emitEvent: boolean; updateOnlySelf: boolean }
  ): void {
    // Iterate through all keys in the values object
    Object.keys(values).forEach((key) => {
      const control = formGroup.get(key);
      const value = values[key];

      // Skip if no matching control
      if (!control) {
        console.warn(`No control found for key: ${key}`);
        return;
      }

      // Recursively patch nested structures
      if (value !== null && typeof value === 'object') {
        this.deepPatchValue(
          control,
          value as { [key: string]: unknown },
          options
        );
      } else {
        // Patch simple values
        control.patchValue(value, {
          emitEvent: options.emitEvent,
          onlySelf: options.updateOnlySelf,
        });
      }
    });
  }

  /**
   * Create a default form control (can be overridden for more complex scenarios)
   */
  private createFormControl(defaultValue: unknown = null) {
    return this.formBuilder.control(defaultValue);
  }
}
