export type ITotalCashFlowDto = {
  income: number;
  expenses: number;
  balance: number;
  avgIncome: number;
  avgExpenses: number;
  avgBalance: number;
  futureIncome: number;
  futureExpenses: number;
  futureBalance: number;
};
