import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { Observable, debounceTime, takeUntil, tap } from 'rxjs';
import { FormControlComponent } from '../../form-control/form-control.component';
import { InputDirective } from '../../../../directives/input.directive';
import { FormErrorComponent } from '../../form-error/form-error.component';
import { BaseInputComponent } from '../../base-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { SelectInputOption } from '../select-input-option';

@Component({
  selector: 'ui-form-observable-select-input',
  standalone: true,
  templateUrl: './form-observable-select-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormControlComponent,
    InputDirective,
    FormErrorComponent,
    TranslateModule,
  ],
})
export class FormObservableSelectInputComponent
  extends BaseInputComponent<string | number | null>
  implements OnInit
{
  @Input() inline = false;
  @Input() hidePlaceholder = false;
  @Input() data: Observable<SelectInputOption[]> | null = null;
  @Input() helperText?: string;

  constructor(@Self() @Optional() control: NgControl) {
    super(control);
  }

  override ngOnInit(): void {
    this._initControlValidation();
    this.formControl.valueChanges
      .pipe(
        debounceTime(200),
        tap((value: string | number | null) => {
          if (value === null || value === '' || value === 'null') {
            this.onChange(null);
          } else if (!isNaN(+value)) {
            this.onChange(+value);
          } else {
            this.onChange(value);
          }
        }),
        takeUntil(this._ngUnSubscribe)
      )
      .subscribe();
  }
}
