import { EnvironmentType, LogLevel } from '@budget-compass/frontend-core';
import { IAppEnvironment } from '../app/core/interfaces/app-environment.interface';
import versionInfo from '../../version.json';
export const environment: IAppEnvironment = {
  production: false,
  apiUrl: 'https://apibudgetcompassdev.amfamily.it/api/v1',
  authApiUrl: 'https://apibudgetcompassdev.amfamily.it/api/v1',
  logLevel: LogLevel.debug,
  environmentType: EnvironmentType.Development,
  version: versionInfo.version,
};
