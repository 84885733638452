import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { NEVER, Subject, takeUntil, throwError } from 'rxjs';
import { ErrorLoggingService } from './error-logging.service';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ERROR_DIALOG_ID } from '..';
import { NewVersionAvailableDialogComponent } from './new-version-available-dialog/new-version-available-dialog.component';
import { ComponentType } from '@angular/cdk/portal';
import { NEW_VERSION_STATUS_CODE } from '@framework/shared/util-constants';
import { ValidationError } from './validation-error-dialog/validation-error.model';
import { ValidationErrorDialogComponent } from './validation-error-dialog/validation-error-dialog.component';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService implements OnDestroy {
  private readonly _ModelValidationErrorName =
    'One or more validation errors occurred.';

  private _dialogRef: DialogRef | null = null; // Track the open dialog reference
  private _isDialogOpen = false; // Flag to track dialog state
  private _onDestroy$ = new Subject<boolean>();

  constructor(
    private _loggingService: ErrorLoggingService,
    private _translate: TranslateService,
    private _dialog: Dialog,
    private _zone: NgZone
  ) {}

  ngOnDestroy() {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();
  }

  /**
   * Handles HTTP and non-HTTP errors.
   * @param error The error to handle (can be HttpErrorResponse or any other error).
   * @returns An observable that emits the error (for further handling) or NEVER (to stop propagation).
   */
  handleError(error: unknown) {
    // Log the error
    this._loggingService.logError('Error occurred:', error);

    // Handle HTTP errors
    if (error instanceof HttpErrorResponse) {
      return this.handleHttpError(error);
    }
    // Handle non-HTTP errors (e.g., runtime errors)
    else {
      return this._handleNonHttpError(error as Error);
    }
  }

  /**
   * Handles HTTP errors.
   * @param response The HTTP error response.
   * @returns An observable that emits the error (for further handling) or NEVER (to stop propagation).
   */
  private handleHttpError(response: HttpErrorResponse) {
    const status = response.status;
    let errorMessage = '';

    switch (status) {
      case 0:
        errorMessage = this._translate.instant(
          'util-error-handling.http-error.no-connection'
        );
        break;
      case HttpStatusCode.Unauthorized:
        errorMessage = this._translate.instant(
          'util-error-handling.http-error.unauthorized'
        );
        break;
      case HttpStatusCode.NotFound:
        errorMessage = this._translate.instant(
          'util-error-handling.http-error.not-found'
        );
        break;
      case HttpStatusCode.BadRequest:
        if (response.error.errorMessage !== this._ModelValidationErrorName) {
          if (response.error && response.error.errorMessage) {
            errorMessage = response.error.errorMessage;
          } else {
            errorMessage = this._translate.instant(
              'util-error-handling.http-error.server-error'
            );
          }
        }
        break;
      case HttpStatusCode.InternalServerError:
        errorMessage = this._translate.instant(
          'util-error-handling.http-error.server-error'
        );
        break;
      case NEW_VERSION_STATUS_CODE:
        this._openDialog(NewVersionAvailableDialogComponent, null, true);
        return NEVER; // Stop error propagation
    }

    // Handle validation errors (standardized format)
    if (
      response.status === HttpStatusCode.BadRequest &&
      response.error.errorMessage === this._ModelValidationErrorName
    ) {
      // Handle validation errors
      const validationErrors = response.error.payload as ValidationError[];
      this._openDialog(ValidationErrorDialogComponent, { validationErrors });
      return throwError(() => validationErrors);
    }

    // Display a generic error message for unhandled HTTP errors
    if (!errorMessage) {
      errorMessage = this._translate.instant(
        'util-error-handling.http-error.generic'
      );
    }

    this._openErrorDialog(errorMessage, response);
    return throwError(() => ({
      statusCode: status,
      error: response.error,
      message: errorMessage,
    }));
  }

  /**
   * Handles non-HTTP errors (e.g., runtime errors).
   * @param error The non-HTTP error.
   * @returns An observable that emits the error (for further handling).
   */
  private _handleNonHttpError(error: Error) {
    const errorMessage = this._translate.instant(
      'util-error-handling.http-error.generic'
    );
    this._openErrorDialog(errorMessage, error);
    return throwError(() => error);
  }

  /**
   * Opens a dialog if it is not already open.
   * @param component The component to display in the dialog.
   * @param data Optional data to pass to the dialog component.
   */
  private _openDialog<TComponent extends ComponentType<unknown>, TData>(
    component: TComponent,
    data?: TData,
    disableClose = false
  ) {
    this._zone.run(() => {
      if (this._isDialogOpen) {
        console.warn('Dialog is already open. Skipping new dialog.');
        return;
      }

      this._isDialogOpen = true; // Set the flag to true

      this._dialogRef = this._dialog.open(component, {
        id: ERROR_DIALOG_ID,
        data,
        disableClose,
      });

      this._dialogRef.closed.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
        this._isDialogOpen = false; // Reset the flag when the dialog closes
        this._dialogRef = null; // Clear the dialog reference
      });
    });
  }

  /**
   * Opens the error dialog if it is not already open.
   * @param message The error message to display.
   */
  private _openErrorDialog<TError>(message: string, error?: TError) {
    this._openDialog(ErrorDialogComponent, { message, error });
  }
}
