import { IBaseModel, IUserData, Uuid } from '@framework/shared/core-util-types';
import {
  BudgetType,
  TransactionSource,
  TransactionSplitType,
  TransactionStatus,
  TransactionType,
} from '../enums';
import { ICategoryEntity } from './category.interface';
import { IBankAccountEntity } from './bank-account.interface';
import { IMonthEndCloseEntity } from './month-end-close.interface';
import { ITransactionSplitAmount } from './transaction-split-amount.interface';

export type ITransactionEntity = {
  amount: number;
  owedAmount?: number;
  transactionSource: TransactionSource;
  categoryId?: Uuid;
  category?: ICategoryEntity;
  date: Date;
  notes?: string;
  fromRecurring: boolean;
  status: TransactionStatus;
  bankAccountId: Uuid;
  bankAccount: IBankAccountEntity;
  monthEndCloseId?: Uuid;
  monthEndClose?: IMonthEndCloseEntity;
  budgetType?: BudgetType;
  transactionType: TransactionType;
  tagIds: Uuid[];
  isHouseholdExpense: boolean;
  splitType?: TransactionSplitType;
  // splitWithUserIds: Uuid[];
  splitAmounts?: ITransactionSplitAmount[];
  parentTransactionId?: Uuid;
  parentTransaction?: ITransactionEntity;
} & IBaseModel &
  IUserData;

export type ITransaction = ITransactionEntity;
