import { NgModule } from '@angular/core';
import { InputDirective } from '../../directives/input.directive';
import { FormControlComponent } from './form-control/form-control.component';
import { FormSelectInputComponent } from './form-select/form-select-input/form-select-input.component';
import { FormTextInputComponent } from './form-text-input/form-text-input.component';
import { FormEnumSelectInputComponent } from './form-select/form-enum-select-input/form-enum-select-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormNumberInputComponent } from './form-number-input/form-number-input.component';
import { ParseErrorMessagePipe } from './parse-error-message.pipe';
import { FormMultipleSelectInputComponent } from './form-select/form-multiple-select-input/form-multiple-select-input.component';
import { FormObservableSelectInputComponent } from './form-select/form-observable-select-input/form-observable-select-input.component';
import { FormDatePickerComponent } from './form-date-picker/form-date-picker.component';
import { FormCheckboxGroupComponent } from './form-checkbox-group/form-checkbox-group.component';
import { FormSubmitActionsComponent } from './form-submit-actions/form-submit-actions.component';
import { ButtonDirective } from '../../directives';
import { FormMonthPickerComponent } from './form-month-picker/form-month-picker.component';
import { FormToggleSwitchComponent } from './form-toggle-switch/form-toggle-switch.component';
import { FormGridDirective } from '../../directives/form-grid.directive';

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    FormTextInputComponent,
    FormSelectInputComponent,
    FormControlComponent,
    InputDirective,
    FormEnumSelectInputComponent,
    FormNumberInputComponent,
    ParseErrorMessagePipe,
    FormMultipleSelectInputComponent,
    FormObservableSelectInputComponent,
    FormDatePickerComponent,
    FormCheckboxGroupComponent,
    FormSubmitActionsComponent,
    FormMonthPickerComponent,
    ButtonDirective,
    FormToggleSwitchComponent,
    FormGridDirective,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    FormTextInputComponent,
    FormSelectInputComponent,
    FormControlComponent,
    InputDirective,
    FormEnumSelectInputComponent,
    FormNumberInputComponent,
    ParseErrorMessagePipe,
    FormMultipleSelectInputComponent,
    FormObservableSelectInputComponent,
    FormDatePickerComponent,
    FormCheckboxGroupComponent,
    FormSubmitActionsComponent,
    FormMonthPickerComponent,
    ButtonDirective,
    FormToggleSwitchComponent,
    FormGridDirective,
  ],
})
export class FormComponentsModule {}
