/**
 * @Planned
 * For future transactions that are anticipated but haven't occurred yet.
 * Useful for budgeting and forecasting expenses.
 * Helps users plan for upcoming bills or expected purchases.
 * @Recorded
 * The default status for most transactions when they are entered into the system.
 * Represents transactions that have occurred and been logged by the user.
 * @Verified
 * The transaction has been checked against a bank statement or official record.
 * Useful for users who regularly reconcile their accounts.
 * Provides an extra level of confidence in the accuracy of the transaction.
 * @Closed
 * The month was successfully closed and all the transactions of that month can't be modified any more.
 */
export enum TransactionStatus {
  Recorded,
  Verified,
  Closed,
}
