import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { ReactiveFormsModule, NgControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormControlComponent } from '../form-control/form-control.component';
import { FormErrorComponent } from '../form-error/form-error.component';
import { BaseInputComponent } from '../base-input.component';
import { takeUntil } from 'rxjs';
import { FocusableDirective, InputDirective } from '../../../directives';

@Component({
  selector: 'ui-form-number-input',
  standalone: true,
  templateUrl: './form-number-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormControlComponent,
    InputDirective,
    FormErrorComponent,
    FocusableDirective,
  ],
})
export class FormNumberInputComponent
  extends BaseInputComponent<number | null>
  implements OnInit
{
  @Input() percent = false;

  constructor(@Self() @Optional() control: NgControl) {
    super(control);
  }

  override ngOnInit(): void {
    this._initControlValidation();
    this.formControl.valueChanges
      .pipe(takeUntil(this._ngUnSubscribe))
      .subscribe((value: number | null) => {
        if (value === null) {
          this.onChange(null);
        } else {
          this.onChange(this.percent ? value / 100 : value);
        }
      });
  }

  override writeValue(value: number): void {
    if (this.percent && value !== undefined) {
      this.formControl.setValue(value * 100);
    } else {
      super.writeValue(value);
    }
  }
}
