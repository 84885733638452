import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'ui-form-submit-actions',
  standalone: true,
  templateUrl: './form-submit-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslateModule, FormsModule],
})
export class FormSubmitActionsComponent {
  private _class = 'flex justify-end gap-3 w-full';
  @HostBinding('class') get class() {
    return this._class;
  }
  @Input() showSaveAndNew = false;

  @Output() goBack: EventEmitter<void> = new EventEmitter<void>();
  @Output() submitForm: EventEmitter<boolean> = new EventEmitter<boolean>();

  public saveAndNew = false;
}
