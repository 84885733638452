import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * @description Compares the password and confirm password fields
 * @returns {ValidatorFn} A function that returns an object with a passwordMismatch key if the passwords do not match
 * @example
 * ```typescript
 * const form = new FormGroup(
 *    {
 *       actualPassword: new FormControl('', [Validators.required]),
 *       newPassword: new FormControl('', [Validators.required]),
 *       confirmPassword: new FormControl('', [Validators.required]),
 *    },
 *    {
 *       validators: [passwordMatchValidator()] as ValidatorFn[],
 *    }
 * );
 * ```
 */
export function passwordMatchValidator(): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const newPasswordController = formGroup.get('newPassword');
    const confirmPasswordControl = formGroup.get('confirmPassword');

    if (!newPasswordController || !confirmPasswordControl) {
      return null;
    }

    const email = newPasswordController.value;
    const confirmEmail = confirmPasswordControl.value;

    if (
      newPasswordController.errors &&
      'email' in newPasswordController.errors
    ) {
      // If email control has other errors, return
      return null;
    }

    if (email) {
      if (!confirmEmail) {
        confirmPasswordControl.setErrors({ required: true });
        return { passwordMismatch: true };
      } else if (email !== confirmEmail) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
        return { passwordMismatch: true };
      } else {
        confirmPasswordControl.setErrors(null);
      }
    } else {
      confirmPasswordControl.setErrors(null);
    }

    return null;
  };
}
