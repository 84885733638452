import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_ENV } from '../constants';
import { EnvironmentType, IEnvironment } from '../interfaces';
import {
  HEADER_CLIENT_ENVIRONMENT,
  HEADER_CLIENT_VERSION,
} from '@framework/shared/util-constants';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
  constructor(@Inject(APP_ENV) private _appEnv: IEnvironment) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.indexOf('/assets/i18n/') === -1) {
      return next.handle(
        request.clone({
          setHeaders: {
            [HEADER_CLIENT_VERSION]: this._appEnv.version,
            [HEADER_CLIENT_ENVIRONMENT]:
              EnvironmentType[this._appEnv.environmentType],
          },
        })
      );
    }
    return next.handle(request);
  }
}
