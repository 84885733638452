import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { APP_NAME } from '@budget-compass/frontend-core';
import { SidebarItem } from './sidebar/sidebar-item';

@Component({
  selector: 'ui-main-navbar',
  template: `
    <nav
      class="w-full bg-white h-11 py-2 px-4 md:px-0 border-b md:border-b-0 border-gray-200"
    >
      <div class="container mx-auto flex justify-between items-center">
        <div class="w-full flex justify-between items-center">
          <div class="flex items-center justify-start">
            <div class="flex items-center justify-start">
              <button
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                class="inline-flex items-center p-2 text-sm rounded-lg hover:bg-gray-100/30 md:hidden"
              >
                <span class="sr-only">Open sidebar</span>
                <i class="fa-solid fa-bars"></i>
              </button>
              <a [routerLink]="['/secure']" class="flex ms-2 md:ms-0 md:me-24">
                <span class="text-xl font-bold">
                  {{ appName }}
                </span>
              </a>
            </div>
            <ul class="hidden w-full md:flex md:w-auto space-x-6">
              <li
                class=""
                *ngFor="
                  let menuItem of menuItems;
                  let i = index;
                  trackBy: trackBy
                "
              >
                <a
                  *ngIf="!menuItem.subMenu || menuItem.subMenu.length === 0"
                  [routerLink]="menuItem.routerLink"
                  routerLinkActive="underline text-primary-700"
                  class="flex items-center font-semibold text-gray-700 hover:text-primary-300 rounded-lg hover:underline group"
                >
                  {{ menuItem.label }}
                </a>
              </li>
            </ul>
          </div>
          <ng-content select="[data-navbar-content]"></ng-content>
        </div>
      </div>
    </nav>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterModule],
})
export class MainNavbarComponent {
  public readonly appName: string;

  @Input() menuItems: SidebarItem[] = [];

  constructor(@Inject(APP_NAME) appName: string) {
    this.appName = appName;
  }

  trackBy(index: number): number {
    return index;
  }
}
