<div class="flex items-center">
  <label class="inline-flex items-center cursor-pointer">
    <input
      type="checkbox"
      [id]="id"
      [formControl]="formControl"
      [disabled]="formControl.disabled"
      class="sr-only peer"
      [disabled]="disabled"
    />
    <div
      class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all peer-checked:bg-primary-600"
    ></div>
    <span class="ms-3 text-sm font-medium text-primary-900">
      <ng-content></ng-content>
    </span>
  </label>
</div>
