import { Directive, ElementRef } from '@angular/core';

@Directive({ selector: '[uiFocusable]', standalone: true })
export class FocusableDirective {
  constructor(public elementRef: ElementRef) {}

  focus() {
    this.elementRef.nativeElement.focus();
  }
}
