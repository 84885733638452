<ui-form-control
  [label]="label"
  [id]="id"
  [isRequired]="hasRequiredValidator ?? false"
>
  <div class="relative w-full">
    <!-- *ngIf="(!hasRequiredValidator ?? false) && formControl.value !== null" -->
    <div
      *ngIf="!hasRequiredValidator && formControl.value !== null"
      class="absolute grid w-5 h-5 place-items-center text-blue-gray-500 top-2/4 right-3 -translate-y-2/4 z-10 cursor-pointer"
      (click)="formControl.reset(null)"
    >
      <i class="fa-solid fa-circle-xmark bg-white"></i>
    </div>
    <input
      type="number"
      [tabIndex]="tabIndex"
      [formControl]="formControl"
      [placeholder]="placeholder"
      [id]="id"
      [name]="name"
      uiInput
      uiFocusable
      [class.invalid-input]="(isControlValid$ | async) === false"
      (blur)="onTouch()"
    />
  </div>
  <ui-form-error
    [isValid]="isControlValid$ | async"
    [errorMessages]="errorMessages"
    [hasErrors]="hasErrors"
  ></ui-form-error>
</ui-form-control>
