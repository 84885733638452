import { ErrorHandler, Injectable, inject } from '@angular/core';
import { ErrorHandlerService } from './error-handler.service';
import { ChunkErrorRetryService } from './chunk-error/chunk-error-retry.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private errorHandler = inject(ErrorHandlerService);
  constructor(private retryService: ChunkErrorRetryService) {}

  handleError(error: unknown): void {
    // Handle HTTP errors (already handled by the interceptor)
    if (this.isChunkLoadError(error)) {
      console.warn('Chunk loading failed, attempting to recover...', error);
      this.retryService.handleChunkLoadError();
    } else {
      this.errorHandler.handleError(error);
    }
  }

  private isChunkLoadError(error: unknown): boolean {
    if (
      typeof error === 'object' &&
      error !== null &&
      'name' in error &&
      'message' in error
    ) {
      return (
        (error as { name: string; message: string }).name ===
          'ChunkLoadError' ||
        (error as { name: string; message: string }).message.includes(
          'Loading chunk'
        ) ||
        (error as { name: string; message: string }).message.includes(
          'Failed to load'
        )
      );
    }
    return false;
  }
}
