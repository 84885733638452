<ui-form-control
  [label]="label"
  [id]="id"
  [isRequired]="hasRequiredValidator ?? false"
  [inline]="inline"
>
  <div class="relative w-full">
    <div
      *ngIf="(!hasRequiredValidator ?? false) && formControl.value !== null"
      class="absolute grid w-5 h-5 place-items-center text-blue-gray-500 top-2/4 right-2 -translate-y-2/4 z-10 cursor-pointer"
      (click)="formControl.reset()"
    >
      <i class="fa-solid fa-circle-xmark bg-white"></i>
    </div>
    <select
      [formControl]="formControl"
      [id]="id"
      [name]="name"
      uiInput
      [size]="size"
      [class.disabled]="formControl.disabled"
      [class.invalid-input]="(isControlValid$ | async) === false"
      (blur)="onTouch()"
    >
      <option
        [value]="null"
        [disabled]="hasRequiredValidator ?? false"
        selected
        *ngIf="!hidePlaceholder"
      >
        {{
          placeholder !== ''
            ? placeholder
            : ('Default.formComponents.selectAnOption' | translate)
        }}
      </option>
      <option
        *ngFor="let option of options | sortOptions"
        [value]="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
  <ui-form-error
    [isValid]="isControlValid$ | async"
    [errorMessages]="errorMessages"
    [hasErrors]="hasErrors"
  ></ui-form-error>
</ui-form-control>
